<template>
    <button
        :class="[color, size, customClass]"
        :disabled="disabled"
        class="myDisabled"
        @click.prevent="click(...params)"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        // 按鈕顏色
        color: {
            type: String,
            default: "bg-red-500 text-white",
        },
        // 按鈕尺寸
        size: {
            type: String,
            default:
                "md:text-sm text-xs px-5 py-2 rounded-lg min-w-[150px] max-w-[250px] ",
        },
        // 自定義 class
        customClass: {
            type: String,
            default: "",
        },
        // 傳入參數
        params: {
            type: Array,
            default() {
                return [];
            },
        },
        // 判斷是否 disabled
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        click(...val) {
            this.$emit("onClick", ...val);
        },
    },
};
</script>
